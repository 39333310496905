import HttpService from "./htttp.service";

class StoreService {
  // authEndpoint = process.env.API_URL;

  getStoreInfo = async () => {
    const getStoreEndpoint = 'store/get-store-list';
    return await HttpService.post(getStoreEndpoint, {data: null});
  };

  getEmailInfo = async () => {
    const getEmailEndpoint = 'store/get-email-list';
    return await HttpService.post(getEmailEndpoint, {data: null});
  };

  getSingleEmailInfo = async () => {
    const getEmailEndpoint = 'store/get-single-sends-emails';
    return await HttpService.post(getEmailEndpoint, {data: null});
  };

  getSingleEmailDetailInfo = async (sinleSendId) => {
    const getEmailEndpoint = 'store/get-single-sends-emails-detail';
    return await HttpService.post(getEmailEndpoint, {data: {sinleSendId}});
  };

  getEmailListWithQuery = async (subject, filterOption) => {
    const getEmailEndpoint = 'store/get-email-list-with-query';
    return await HttpService.post(getEmailEndpoint, {data: {subject, filterOption}});
  };

  getEmailFromSingleId = async (sinleSendId) => {
    const getEmailEndpoint = 'store/get-single-email-from-id';
    return await HttpService.post(getEmailEndpoint, {data: {sinleSendId}});
  };

  getAllActivityEmailFromId = async (msgId) => {
    const getEmailEndpoint = 'store/get-email-sendgrid';
    return await HttpService.post(getEmailEndpoint, {data: {msgId}});
  };  
}

export default new StoreService();
