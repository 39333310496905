/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import emailTableData from "layouts/email-list/data/emailTableData";
import { Button, CircularProgress, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useEffect, useState } from "react";
import StoreService from "services/store-service";
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const convertTimestampToDate = (timestamp) => {
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert to milliseconds
  return date.toLocaleString(); // Customize format as needed
};

// Function to format details
const formatDetails = (details) => {
  if (!details) return [];
  
  // Combine and sort data
  const allEntries = [...details.open, ...details.click];
  allEntries.sort((a, b) => b.messageTime - a.messageTime); // Sort by messageTime in descending order
  
  return allEntries.map(entry => ({
    ...entry,
    formattedDate: convertTimestampToDate(entry.messageTime)
  }));
};

function Tables() {
  // const { columns, rows, emailLoading } = emailTableData();
  const [singleSendsEmails, setSingleSendsEmails] = useState([]);
  const [emailLoading, setEmailLoading] = useState(false);

  useEffect(() => {
    const initStoreData = async () => {      
      const responseFromBackend = await StoreService.getSingleEmailInfo();
      const { response } = responseFromBackend;
      console.log(response)
      setSingleSendsEmails(response.result);
    }
    initStoreData();
  }, []);

  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleClickOpen = async (row) => {    
    const responseFromSendGrid = await StoreService.getAllActivityEmailFromId(row?.original?.msg_id);
    setSelectedRow(responseFromSendGrid.messageDetails);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const [selectedEmailName, setSelectedEmailName] = useState('no choose');
  const [clickUrls, setClickUrls] = useState([]);
  const [selectedFilterOption, setSelectedFilterOption] = useState('no choose');
  const [selectedSingleEmailDetail, setSelectedSingleEmailDetail] = useState(null);
  const [mailList, setMailList] = useState({
    columns: [
      { Header: "Recipient", accessor: "to_email", align: "left", style: { width: '200px' } },
      { Header: "Subject", accessor: "subject", align: "left", style: { width: '200px' } },
      { Header: "Opened", accessor: "opens_count", align: "center", style: { width: '50px' } },
      { Header: "Clicked", accessor: "clicks_count", align: "center", style: { width: '50px' } },
      { Header: "Last Activity", accessor: "last_event_time", align: "center", style: { width: '200px' } }
    ],
    rows: [],
  });

  const handleChange = async (event) => {
    const selectedName = event.target.value;
    setSelectedEmailName(selectedName);

    // Call API with selected email name
    if (selectedName !== 'no choose') {     
      const responseFromSingleId = await StoreService.getEmailFromSingleId(selectedName);
      console.log(responseFromSingleId.response)
      setSelectedSingleEmailDetail(responseFromSingleId.response);
      const responseFromSendGrid = await StoreService.getSingleEmailDetailInfo(selectedName);
      const { response } = responseFromSendGrid;
      console.log(response)
      setClickUrls(response.results);
    }    
  };

  const handleFilterOptionChange = async (event) => {
    const selectedName = event.target.value;
    setSelectedFilterOption(selectedName);

    // Call API with selected email name
    if (selectedName !== 'no choose') {
      setEmailLoading(true);
      const responseFromSendGrid = await StoreService.getEmailListWithQuery(selectedSingleEmailDetail?.email_config?.subject, selectedName);
      const { response } = responseFromSendGrid;
      setMailList({
        columns: [
          { Header: "Recipient", accessor: "to_email", align: "left", style: { width: '200px' } },
          { Header: "Subject", accessor: "subject", align: "left", style: { width: '200px' } },
          { Header: "Opened", accessor: "opens_count", align: "center", style: { width: '50px' } },
          { Header: "Clicked", accessor: "clicks_count", align: "center", style: { width: '50px' } },
          { Header: "Last Activity", accessor: "last_event_time", align: "center", style: { width: '200px' } }
        ],
        rows: response.messages,
      });
      setEmailLoading(false);
    }    
  };  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Email Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3} className={'flex gap-10 pt-20 px-20'}>
                <MDTypography color="black" className="w-[30%]">
                  Marketing Emails
                </MDTypography>
                <Select
                  labelId="email-select-label"
                  value={selectedEmailName}
                  label="Select Email"
                  onChange={handleChange}
                  className="w-[500px]"
                >
                  <MenuItem value='no choose'>
                    <em>No Choose</em>
                  </MenuItem>
                  {singleSendsEmails.map((email, index) => (
                    <MenuItem key={index} value={email.id}>
                      {email.name}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
              <MDBox pt={3} className={'flex gap-10 px-20'}>
                <MDTypography color="black" className="w-[30%]">
                  Filter Option
                </MDTypography>
                <Select
                  labelId="filter-option"
                  value={selectedFilterOption}
                  label="Filter Option"
                  onChange={handleFilterOptionChange}
                  className="w-[500px]"
                >
                  <MenuItem value='no choose'>
                    <em>No Choose</em>
                  </MenuItem>
                  <MenuItem value='open'>
                    <b>Opened</b>
                  </MenuItem>
                  {clickUrls.map((clickUrl, index) => (
                    <MenuItem key={index} value={clickUrl.url}>
                      Click url : {clickUrl.url}
                    </MenuItem>
                  ))}
                </Select>
              </MDBox>
              {mailList && Object.keys(mailList).length > 0 && (
                <>
                  <MDTypography color="black" className="w-[30%] px-10 pt-10">
                    Email List
                  </MDTypography>
                  <MDBox pt={3}>
                    {emailLoading ? (
                      <MDBox className="mx-auto w-full text-center">
                        <CircularProgress/>
                      </MDBox>
                    ) : (
                      <DataTable
                        table={{ columns: mailList.columns, rows: mailList.rows }}
                        isSorted={true}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        selectRow={(row) => handleClickOpen(row)}
                      />
                    )}                
                  </MDBox>
                </>
              )}              
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Activity Details</DialogTitle>
        <DialogContent className="min-w-[500px]">
          {selectedRow?.events.length > 0 ? (
            selectedRow.events
              .sort((a, b) => new Date(b.processed) - new Date(a.processed)) // Sort in descending order
              .map((entry, index) => (
                <Typography key={index} variant="body2" className="pt-5">
                  <b>Event: {entry.event_name}</b><br />
                  {`Date: ${entry.processed}`}<br />
                  {entry.url && `Click url: ${entry.url}`}<br />
                </Typography>
              ))
          ) : (
            <Typography variant="body2">No activity</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
