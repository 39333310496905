/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";
import StoreService from "services/store-service";

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const convertTimestampToDate = (timestamp) => {
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert to milliseconds
  return date.toLocaleString(); // Customize format as needed
};

export default function data() {
  const [emailList, setEmailList] = useState([]);
  const [emailLoading, setEmailLoading] = useState(false);
  useEffect(() => {
    const initStoreData = async () => {      
      const response = await StoreService.getEmailInfo();
      const { uniqueMessages } = response;

      // Transform the data to match the table structure
      const transformedData = [];

      // Iterate through each shopEmail
      Object.entries(uniqueMessages).forEach(([shopEmail, messages]) => {
        if (Object.keys(messages).length === 0) {
          // If messages is an empty object, push a row with no email and 0 counts
          transformedData.push({
            shopEmail,           // Email of the recipient
            mail: "No email",    // No email messages
            open: 0,             // 0 opens
            click: 0             // 0 clicks
          });
        } else {
          // For each messageId (i.e., each unique message)
          Object.entries(messages).forEach(([messageId, { subject, open, click }]) => {
            const lastOpenTime = open.length ? Math.max(...open.map(activity => activity.messageTime)) : 0;
            const lastClickTime = click.length ? Math.max(...click.map(activity => activity.messageTime)) : 0;
            const lastActivityTime = Math.max(lastOpenTime, lastClickTime);
            transformedData.push({
              shopEmail,            // Email of the recipient
              mail: subject || messageId,      // Unique message ID
              open: open.length,    // Total open count for this message
              click: click.length,   // Total click count for this message
              messageId,
              detailed: {open, click},
              lastActivity: lastActivityTime ? convertTimestampToDate(lastActivityTime) : 'No activity'
            });
          });
        }
      });

      setEmailList(transformedData);
      setEmailLoading(false);
    }
    initStoreData();
    setEmailLoading(true);
  }, []);

  return {
    columns: [
      { Header: "Recipient", accessor: "shopEmail", align: "left", style: { width: '200px' } },
      { Header: "Mail", accessor: "mail", align: "left", style: { width: '200px' } },
      { Header: "Opened", accessor: "open", align: "center", style: { width: '50px' } },
      { Header: "Clicked", accessor: "click", align: "center", style: { width: '50px' } },
      { Header: "Last Activity", accessor: "lastActivity", align: "center", style: { width: '200px' } }
    ],
    rows: emailList,
    emailLoading
  };
}
