/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { useEffect, useState } from "react";
import StoreService from "services/store-service";

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
export default function data() {
  const [storeList, setStoreList] = useState([]);
  const [storeLoading, setStoreLoading] = useState(false);
  useEffect(() => {
    const initStoreData = async () => {      
      const response = await StoreService.getStoreInfo();
      setStoreList(response.storeList);
      setStoreLoading(false);
    }
    initStoreData();
    setStoreLoading(true);
  }, []);

  return {
    columns: [
      { Header: "Store name", accessor: "store-name", align: "left" },
      { Header: "Store link", accessor: "store-link", align: "left" },
      { Header: "Installation date", accessor: "installation-date", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Owner email", accessor: "owner-email", align: "center" },
      { Header: "Amount of Orders", accessor: "amount-orders", align: "center" },
      { Header: "Amount of Products", accessor: "amount-products", align: "center" },
    ],
    rows: storeList,
    storeLoading
  };
}
